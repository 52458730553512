import React from "react";
import './WelcomeMessage.css';
import './WelcomeMessage.mobile.css';


export default class WelcomeMessage extends React.Component {
  render() {
    return <div className="welcome-container">
      <div className="prompt-box">
        {this.props.promptExamples.map(p => <div onClick={() => this.props.triggerSearch(p.text)} class="prompt">
            {p.icon}
            <p><strong>{p.text}</strong></p>
        </div>)}
      </div>
    </div>
  }
}