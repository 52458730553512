import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ConversationRepository from "../repository/ConversationRepository";
import './ConversationWidget.css';
import './ConversationWidget.mobile.css';

export default class ConversationWidget extends React.Component {

    render() {
        return <div className={`conversation-widget ${this.props.isVisible ? 'visible' : ''}`}>
            {this.props.conversations ? Object.entries(ConversationRepository.categorizeConversations(this.props.conversations))
                .map(([key, value]) => {
                    return {
                        category: key,
                        list: value
                    };
                })
                .filter((c) => c.list.length > 0)
                .map(c => <>
                    <span className="chat-sidebar-1-span">{c.category}</span>
                    {c.list.map(l => <div className="chat-sidebar-field">
                        <button data-active={l.isActive === true} onClick={() => this.props.onConversationClick(l.id)}>{l.title ?? "Current conversation"}</button>
                        <button onClick={() => this.props.deleteConversation(l.id)} className="delete-conversation">
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>)}
                </>
                ) : <></>}
        </div>
    }
}