import React, { useState, useEffect, useRef } from "react";
import udmey from "../assets/udmey-icon.png";
import demand from "../assets/demand.png";
import price from "../assets/price.png";
import time from "../assets/time.png";
import arrowRight from "../assets/arrow-right.png";
import Message, { MessageSender } from "../model/Message";
import ConversationRepository from "../repository/ConversationRepository";
import ChatApi from "../api/Api";
import Course from "../model/Course";
import Conversation from "../model/Conversation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCode, faLaptopCode, faClock, faGraduationCap, faChevronUp, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import MessageRepository from "../repository/MessageRepository";
import AuthApi from "../api/AuthApi";
import AppHeader from "./AppHeader";
import LoginForm from "./Login";
import WelcomeMessage from "./WelcomeMessage";
import "./chat.css";
import "./chat.new.css";
import "./chat.mobile.css";

const MESSAGE_COUNT = 6;

const Chat = () => {
  const [inputValue, setInputValue] = useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isLoginFormActive, setIsLoginFormActive] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(AuthApi.isLoggedIn());
  let [conversation, setConversation] = useState(null);
  let [conversations, setConversations] = useState(ConversationRepository.getConversations());
  const messagesEndRef = useRef(null);
  const promptExamples = [
    {
      "id": 1,
      "text": "Find me top Python Courses",
      "icon": <FontAwesomeIcon color="#FF5733" icon={faCode}></FontAwesomeIcon>
    },
    {
      "id": 2,
      "text": "Make me job ready in Javascript",
      'icon': <FontAwesomeIcon color="#33B5FF" icon={faLaptopCode}></FontAwesomeIcon>
    },
    {
      "id": 3,
      "text": "Give time management courses 1 hr max.",
      'icon': <FontAwesomeIcon color="#FFC300" icon={faClock}></FontAwesomeIcon>
    },
    {
      "id": 4,
      "text": "I want to practice GRE maths",
      'icon': <FontAwesomeIcon color="#28A745" icon={faGraduationCap}></FontAwesomeIcon>
    }
  ];

  useEffect(() => {
    scrollToBottom();
  }, [conversations]);

  if (conversation == null) {
    Conversation.init()
    Conversation.clearEmptyConversations();
    const urlParams = new URLSearchParams(window.location.search);
    const conversationId = urlParams.get('id'); // '123'
    setConversation(ConversationRepository.getActiveConversation(conversationId));
  }

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const handleSubmission = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior (page reload)

    setIsInputDisabled(true);
    const prompt = inputValue;
    setInputValue('');

    const message = new Message({
      content: prompt,
      sender: MessageSender.ME
    });
    conversation.addMessage(message);
    setConversation(conversation);
    setConversations(ConversationRepository.getConversations());

    let futureMessage = new Message({
      content: null,
      sender: MessageSender.ASSISTANT
    });

    conversation.addMessage(futureMessage);
    setConversation(conversation);
    setConversations(ConversationRepository.getConversations());

    // await AppTools.wait(3000);
    const response = await ChatApi.search(prompt, conversation);
    const jsonData = await response.json();

    conversation.title ??= jsonData.title;
    futureMessage.content = jsonData.message;
    futureMessage.see_more = jsonData.see_more;
    futureMessage.courses = Course.fromList(jsonData.results).slice(0, 4);
    conversation.addMessage(futureMessage);
    conversation = ConversationRepository.save(conversation);
    setConversation(conversation);
    setConversations(ConversationRepository.getConversations());


    // if(res) {
    //   setIsInputDisabled(false);
    // }
    setIsInputDisabled(false);
    console.log(prompt);
  };

  const onKeyUp = (e) => {
    if (e.altKey === false && e.keyCode === 13 && e.key === "Enter") {
      handleSubmission(e);
    }
  }

  const triggerSearch = async (prompt) => {
    if (MessageRepository.getMessageCount() > MESSAGE_COUNT && AuthApi.isTokenExpired()) {
      setIsLoginFormActive(true);
    } else {

      setIsInputDisabled(true);

      const message = new Message({
        content: prompt,
        sender: MessageSender.ME
      });
      conversation.addMessage(message);
      setConversation(conversation);
      setConversations(ConversationRepository.getConversations());

      let futureMessage = new Message({
        content: null,
        sender: MessageSender.ASSISTANT
      });

      conversation.addMessage(futureMessage);
      setConversation(conversation);
      setConversations(ConversationRepository.getConversations());

      // await AppTools.wait(3000);
      const response = await ChatApi.search(prompt, conversation);
      const jsonData = await response.json();

      conversation.title ??= jsonData.title;
      futureMessage.content = jsonData.message;
      futureMessage.see_more = jsonData.see_more;
      futureMessage.keywords = jsonData.keywords;
      futureMessage.courses = Course.fromList(jsonData.results).slice(0, 4);
      conversation.addMessage(futureMessage);
      conversation = ConversationRepository.save(conversation);
      setConversation(conversation);
      setConversations(ConversationRepository.getConversations());

      setIsInputDisabled(false);
    }
  }

  const onConversationClick = (id) => {
    let conversation = ConversationRepository.getConversation(id);
    conversation.setActive();

    // Assume conversationId is already defined
    const conversationId = conversation.id;
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('id', conversationId);
    window.history.replaceState({}, '', currentUrl.toString());

    setConversation(conversation);
    setConversations(ConversationRepository.getConversations());
    scrollToBottom();
  }

  const onCourseClick = (url) => {
    window.open(url, '_blank');
  }

  const onCategoryClicked = (e, category) => {
    e.preventDefault();
    triggerSearch("I'm looking to learn " + category.name, conversation.getMessages());
    scrollToBottom();
  }

  const onNewConversationClicked = () => {
    Conversation.init()
    Conversation.clearEmptyConversations();
    setConversation(ConversationRepository.getActiveConversation());
  }

  const notHappy = () => {
    let lastMessage = Conversation.getLastMessage(MessageSender.ASSISTANT);
    if (lastMessage) {
      let firstKeyword = lastMessage.keywords.length > 0 ? lastMessage.keywords[0] : undefined;

      if (firstKeyword) {
        triggerSearch("Show me more courses about " + firstKeyword, conversation.getMessages());
      }
    }
  }


  const deleteConversation = (id) => {
    setConversations(ConversationRepository.deleteConversation(id));
    Conversation.init()
    Conversation.clearEmptyConversations();
    setConversation(ConversationRepository.getActiveConversation());
  }

  const _renderInput = () => {
    return <div className="chat-input-con">
      <form className="input-area" onSubmit={handleSubmission}>
        <input
          type="text"
          placeholder="What do you want to learn today?"
          className="chat-input"
          onKeyUp={onKeyUp}
          disabled={isInputDisabled}
          value={inputValue}  // Bind input value to state
          onChange={(e) => setInputValue(e.target.value)}
        />

        <button className="submit-input">
          <FontAwesomeIcon color="#000000" icon={faChevronRight}></FontAwesomeIcon>
        </button>
      </form>
    </div>;
  }

  return (
    <>

      <div className="chat-app">
        <AppHeader isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setIsLoginFormActive={setIsLoginFormActive} deleteConversation={deleteConversation} onConversationClick={onConversationClick} conversations={conversations} onNewConversationClicked={() => onNewConversationClicked()} ></AppHeader>
        <div className="chat-main">
          <div className="chat-con">

            <div className="chat-listing">
              {conversation && conversation.getMessages().length > 0 ? conversation.getMessages().map(m => m.sender === MessageSender.ME ?
                <div className="your-prompt">
                  <div>
                    <p>{m.content}</p>
                  </div>
                </div> :
                <div className="search-prompt" data-gradient={m && m.courses.length === 0}>
                  <p className="search-prompt-heading">
                    {m.content ?? "I'm working on your request. Please be with me..."}
                  </p>

                  <div className="course-listing">
                    {m && m.courses ? m.courses
                      .map((c) =>
                        <div onClick={() => onCourseClick(c.url)} className="card">
                          <img
                            src={c.featured_image}
                            alt=""
                            className="card-image"
                          />
                          <span className="card-category">{c.getCategoryNames()}</span>
                          <p className="card-title" dangerouslySetInnerHTML={{ __html: c.title }} />

                          <ul className="card-ul">
                            <li className="card-li">
                              {" "}
                              <img src={udmey} alt="" className="card-li-img" />
                              <p className="card-li-text">{c.provider}</p>
                            </li>
                            {c.course_session.trim().length > 0 ? <li className="card-li">
                              <img src={demand} alt="" className="card-li-img" />
                              <p className="card-li-text">{c.course_session}</p>
                            </li> : <></>}

                            <li className="card-li">
                              <img src={price} alt="" className="card-li-img" />
                              <p className="card-li-text">{c.course_type}</p>
                            </li>
                            {c.duration.trim().length > 0 ? <li className="card-li">
                              {" "}
                              <img src={time} alt="" className="card-li-img" />
                              <p className="card-li-text">{c.duration}</p>
                            </li> : <></>}

                          </ul>
                          <a href={c.url}>
                            <div className="card-button-div">
                              <p className="card-button-text">Read More</p>
                              <div className="card-button-img-div">
                                <img
                                  src={arrowRight}
                                  alt=""
                                  className="card-button-img"
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      ) : <></>}
                  </div>

                  {m.getCategories().length > 0 ? <>
                    <div className="course-categories">
                      <h4>Related categories</h4>
                      <div className="list">
                        {m.getCategories().length > 0 ? m.getCategories().map((course, index) => (
                          <a key={index} rel="norefferer" onClick={(e) => onCategoryClicked(e, course)} className="category" href={course.url} dangerouslySetInnerHTML={{ __html: course.name }}></a>
                        )) : <></>}
                      </div>
                    </div>
                    <div className="quick-actions">
                      {m.courses.length > 0 ? <button className="notHappy" onClick={notHappy}>Suggest me more courses</button> : <></>}
                      {m.courses.length > 0 ? <a className="seeMore" target="_blank" rel="noreferrer" href={m.see_more}>
                        <span>See more</span>
                        <FontAwesomeIcon color="#ffffff" icon={faArrowUpRightFromSquare} />
                      </a> : <></>}
                    </div>
                  </> : <></>}



                </div>


              ) : <WelcomeMessage promptExamples={promptExamples} triggerSearch={triggerSearch} ></WelcomeMessage>}
            </div>

            <div ref={messagesEndRef} />
          </div>
          {_renderInput()}

        </div>

      </div>
      <LoginForm isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setIsLoginFormActive={setIsLoginFormActive} isLoginFormActive={isLoginFormActive}></LoginForm>
    </>
  );
};

export default Chat;
